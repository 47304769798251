import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NeoButtonSection from "../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoColumn from "../../design/design_components/neo/layout/NeoColumn.base";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "../../design/design_components/neo/title/NeoTitleMain.base";
import NewMediaButton from "./components/NewMediaButton.component";
import NeoButtonMain from "../../design/design_components/neo/button/NeoButtonMain.base";
import MediaTable from "./components/CampaignMediaTable.component";
import CampaignResults from "./components/CampaignResultsTable.component";
import CampaignResultsAudience from "./components/CampaignResultsAudienceTable.component";
import Icono from "../../design/assets/img/wfi/icons/icon-ad.svg";
import { useHistory } from 'react-router-dom';
import NeoTitleSecond from "../../design/design_components/neo/title/NeoTitleSecond.base";
import { Tag } from 'primereact/tag';
import NeoTooltip_icon from "../../design/design_components/neo/overlay/NeoTooltip_icon.base";
import NeoTooltip from "../../design/design_components/neo/overlay/NeoTooltip.base";
import NeoCard from "../../design/design_components/neo/panel/NeoCard.base";
import NeoTabView from "../../design/design_components/neo/panel/NeoTabView.base";
import NeoTabPanel from "../../design/design_components/neo/panel/NeoTabPanel.base";
import { CONST } from "../../consts/consts";
import useToastContext from "../../hooks/useToastContext.hook";
import NeoCalendar from "../../design/design_components/neo/form/NeoCalendar.base";
import PauseCampaignButton from "./components/PauseCampaingButton.component";
import { DateTime } from "luxon";
import {
  getCampaignDataInfo,
  getLookerDataInfo,
} from "../../service/Campaign.service";
import "./style.scss";
import moment from "moment-timezone";
import ExcelJs from "exceljs"
import { dateFormat } from '../../utils/date.utils';
import { auth } from "../../config/firebase.config";


export default function CampaignDetails() {
  const history = useHistory();
  const toast = useToastContext();
  const { mediaId } = useParams();
  const { mediaIdCons } = useParams();
  const [media, setMedia] = useState();
  const [loadingLooker, setLoadingLooker] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false); 
 
  const parseDateFromServer = (date) => {
    if (!date) return null; 
    return DateTime.fromSQL(
      DateTime.fromISO(date).setZone("UTC").toSQLDate()
    ).toJSDate();
  };

  const baseURL = "https://lookerstudio.google.com/embed/reporting/d1f61e62-2d29-46b8-8072-243c08356746/page/5oq8D";
  
  const buildUrl = () => {
    const params = {
      startDate: formatDate(form.from),
      endDate: formatDate(form.to),
      userId:auth.currentUser.uid,
      campaignId:mediaId
    };
    //const queryString = new URLSearchParams(params).toString();
    //return `${baseURL}?${queryString}`;
    const paramsAsString = JSON.stringify(params);
    const encodedParams = encodeURIComponent(paramsAsString);
    const urlWithAdditionalText = `${baseURL}?params=${encodedParams}`;
    //setIframeUrl(urlWithAdditionalText);
    return `${baseURL}?params=${encodedParams}`;
  };

  const updateMedia = async (newMediaProperties) => {
    setMedia((prevMedia) => ({ ...prevMedia, ...newMediaProperties }));
    // Trabaja con los nuevos valores aquí:
    const updatedMedia = { ...media, ...newMediaProperties };
    
  };

   // Obtener la información de la campaña
   const gettingCampaignData = async () => {
    let response = await getCampaignDataInfo(mediaId);
 
    if (!response.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
      
    } else {
      const media = response.result;
      await updateMedia({
        id: mediaId,
        name: media.NAME,
        isDefault: Boolean(media.IS_DEFAULT),
        startDate: parseDateFromServer(media.START_DATE),
        endDate: parseDateFromServer(media.END_DATE),
        status: media.STATUS_ID,
        statusName: media.STATUS_NAME,
        isDistributed: Boolean(media.IS_DISTRIBUTED),
      });
   
    }
  };

  useEffect(() => {
    let mount = true;

    if (mount) {
      gettingCampaignData();
    }
    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    if (media) {
     
    }
  }, [media]);

  const handleCreateAd = () => {
    
    history.push(`/administracion-media/nueva-media/${mediaId}/${mediaIdCons}`);
    };
  const handleEditCampaign = () => {
    history.push(`/campanias/edit-campania/${mediaId}/${mediaIdCons}`);  
      };

      // Función para obtener el color del círculo según el estatus
const getStatusColor = (status) => {
  switch (status) {
    case "Activa":
      return "#44A143";
    case "Pausada":
      return "#FFC700";
    case "Finalizada":
      return "#6C757D";
    case "Programada":
      return "#F56B1D";
    case "Incompleta":
      return "#E04460";
    default:
      return "transparent"; // Por si acaso hay un estatus no reconocido
  }
};
const now = new Date();

const handleUpdatedAd = async ({ status }) => {
  updateMedia({ status });

  toast.setMessage(
    CONST.SEVERITY.SUCCESS,
    "Estatus",
    "Se actualizó el estado de la campaña"
  );
};

const handleErrorUpdateAd = () => {
  toast.setMessage(
    CONST.SEVERITY.ERROR,
    CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.HEADLINE,
    CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.DETAILS
  );
};

  // Función para formatear las fechas
  const formatDate = (date) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

const [form, setForm] = useState({
  //store: null,
  //floor: null,
  from: moment(now).toDate(), // Fecha y hora actual
  to: moment(now).toDate() 
  // from: moment(now).subtract(24 * 240, "hour").toDate(),
  // to: moment(now).add(10, "day").toDate()
});

const [iframeUrl, setIframeUrl] = useState(() => buildUrl());

const getTooltipContent = (statusName) => {
  switch (statusName) {
    case 'Activa':
      return 'Esta campaña está siendo mostrada actualmente.';
    case 'Incompleta':
      if (media?.isDistributed) {
        return 'Esta campaña no está siendo mostrada, debes crear mínimo dos anuncios para que tu campaña empiece a funcionar.';
      } else {
        return 'Esta campaña no está siendo mostrada, debes crear mínimo un anuncio para que tu campaña empiece a funcionar.';
      }
    case 'Pausada':
      return 'Esta campaña ha sido pausada, si quieres que vuelva a funcionar debes reactivarla.';
    case 'Finalizada':
      return 'Esta campaña ha finalizado su período de vigencia.';
    case 'Programada':
        return 'Esta campaña ha sido programada, se activará cuando comience su período de vigencia.';
    default:
      return 'Estado desconocido.';
  }
};

//const [iframeUrl, setIframeUrl] = useState("https://lookerstudio.google.com/embed/reporting/f131b6f4-5ee3-40b3-833d-63cc176b8336/page/5oq8D");


  

  // Descarga de datos
async function crearExcel() {
  setLoadingExcel(true); // Activa el spinner
  let dataLooker;

  // Llamada a la función para obtener los datos
  dataLooker = await getLookerDataInfo(mediaId);

  // Si la petición no fue exitosa
  if (!dataLooker.success) {
    toast.setMessage(
      CONST.SEVERITY.ERROR,
      CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
      CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
    );
    setLoadingExcel(false); // Desactiva el spinner
  }
  // Si la petición fue exitosa, pero `result` está vacío
  else if (!dataLooker.result || dataLooker.result.length === 0) {
    toast.setMessage(
      CONST.SEVERITY.WARNING,
      "No existen datos",
      "Sin datos para descarga en esta campaña"
    );
    setLoadingExcel(false); // Desactiva el spinner
  }
  // Si la petición fue exitosa y `result` contiene datos
  else {
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet();

    // Definir encabezados
    const currentHeaders = Object.entries(dataLooker.result[0]).reduce((acc, [key]) => {
      acc.push({ header: key, key })
      return acc;
    }, []);
    
    worksheet.columns = currentHeaders;

    // Agregar filas de datos al worksheet
    worksheet.addRows(dataLooker.result);

    // Exportar como archivo CSV
    await workbook.csv.writeBuffer().then((data) => {
      const blob = new Blob(["\ufeff" + data], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${dateFormat(Date.now())}_datos_campania.csv`);
      link.click();
      setLoadingExcel(false); // Desactiva el spinner
    });
  }
}



const [dateRange, setDateRange] = useState([null, null]);
const handleDateRangeChange = (range) => {
  setDateRange(range); // Actualiza el estado con el nuevo rango de fechas
};


useEffect(() => {
  // Cada vez que cambian las fechas, volvemos a activar el loading
  setLoadingLooker(true);
  setIframeUrl(buildUrl());
}, [form.from, form.to]);




const handleDateChange = (e) => {
  const [startDate, endDate] = e.value;
  setForm((prev) => ({
    ...prev,
    from: startDate,
    to: endDate
  }));
  
  buildUrl(); // Actualiza la URL del iframe cada vez que cambian las fechas
};

  return (
    <>
      <NeoGridContainer>
     

        <div className="p-grid" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>

<NeoTitleSecond
  col="auto"
  title={`Consulta de campaña # ${mediaIdCons}`}
  icon={Icono}
  goBack={true}
/>
{media?.isDefault &&(
<Tag  style={{
  background: '#2196F3',
  //height: '35px',
  marginLeft: '10px',
  display: 'flex',
  alignItems: 'center',
  marginTop: '-15px',
  borderRadius: '8px',
}}>
  <span className="text-default">Default</span>
  <NeoTooltip_icon
    content="Campaña default: Los anuncios de esta campaña se mostrarán cuando no haya otros disponibles. No tiene vigencia ni sucursales."
    extra="inline-tooltip-campanias"
                position="right"
  />
</Tag>
  )}
</div>


        
        <NeoButtonSection
          align="right"
          col="12"
          md="6"
          extra="p-col-align-center p-flex-sm-row p-flex-column"
        >
        {(media?.status === 1 || media?.status === 2) && (
          <PauseCampaignButton
              campaignId={mediaId}
              currentStatus={media.status}
              onUpdateStatusError={handleErrorUpdateAd}
              onUpdateStatusSuccess={handleUpdatedAd}
            />
          )}
        {media?.status!=5 && (
            <NeoButtonMain
        label="Editar"
        icon="pi pi-pencil"
        onClick={handleEditCampaign}
        
      />
    )}
     
          {/* <NewMediaButton /> */}
        </NeoButtonSection>
      </NeoGridContainer>


      
      <NeoCard custom="custom-padding-card">
        
          <NeoColumn col="4">
            Campaña
          </NeoColumn>
          <NeoColumn col="4">
            Fechas de vigencia
          </NeoColumn>
          <NeoColumn col="4">
            Tipo / Estatus
          </NeoColumn>
        
          <NeoColumn col="4">
          <strong># {mediaIdCons} - {media?.name}</strong>
          </NeoColumn>
          <NeoColumn col="4">
          {media?.startDate == null && media?.endDate == null ? (
              <span><strong>Permanente</strong></span>
            ) : (
              <span><strong>{media?.startDate?.toLocaleDateString()} - {media?.endDate?.toLocaleDateString()}</strong></span>
            )}
          </NeoColumn>
          <NeoColumn col="4">
            <div id="campaignInfo" style={{ display: "flex", alignItems: "center" }}>
            {media?.isDefault ? (
              <strong>Campaña default</strong>
            ) : media?.isDistributed ? (
              <strong>Campaña distribuida</strong>
            ) : (
              <strong>Campaña regular</strong>
            )}
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: getStatusColor(media?.statusName),
                  margin: "0 8px"
                }}
              ></span>
              <span>{media?.statusName}</span>
            </div>

            <NeoTooltip target="#campaignInfo"    content={getTooltipContent(media?.statusName)}  position="top" />
          </NeoColumn>
      
      </NeoCard>

      <NeoCard custom="custom-padding-card">
        
      <div className="custom-tab" style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', top: '-5px', right: '10px', zIndex: 1 }}>
        <NeoButtonSection align="right">
          <NeoButtonMain
            label={loadingExcel ? "Cargando..." : "Descargar registros"}
            icon={loadingExcel ? "" : "pi pi-download"}
            className="button-with-spinner"
            onClick={crearExcel}
            disabled={loadingExcel}
          />
        </NeoButtonSection>
      </div>
      <NeoTabView
          panelContainerStyle={{ backgroundColor: "red" }}
      >
          <NeoTabPanel
              unstyled
              key="1"
              header="Anuncios de la campaña"
              headerStyle={{ margin: 0, padding: 0 }}
              style={{ margin: 0, padding: 0 }}
              >
                {media?.status !== undefined && (
                <MediaTable campaignId={mediaId} handleCreateAd={handleCreateAd} campaignIdCons={mediaIdCons} statusCampaign={media?.status}/>
              )}
              </NeoTabPanel>
           <NeoTabPanel
              unstyled
              key="2"
              header="Resultados por sucursales"
              headerStyle={{ margin: 0, padding: 0 }}
              style={{ margin: 0, padding: 0 }}
              >
                 <CampaignResults campaignId={mediaId} handleCreateAd={handleCreateAd}/>
            </NeoTabPanel>
            

            <NeoTabPanel
              unstyled
              key="3"
              header="Resultados por audiencia"
              headerStyle={{ margin: 0, padding: 0 }}
              style={{ margin: 0, padding: 0 }}
              >
                 <CampaignResultsAudience campaignId={mediaId} handleCreateAd={handleCreateAd}/>
            </NeoTabPanel>
  
            <NeoTabPanel
  unstyled
  key="4"
  header="Desempeño general"
  headerStyle={{ margin: 0, padding: 0 }}
  style={{ margin: 0, padding: 0 }}
>
  <NeoCard custom="custom-padding-card">
    {/* Contenedor flex para el rango de fechas y el botón */}
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px', marginTop: '20px', width: '100%' }}>
      <div style={{ maxWidth: 340, marginRight: 'auto' }}> {/* Permite que el calendario se mantenga a la izquierda */}
        <NeoCalendar
          label="Periodo"
          value={[form.from, form.to]}
          onChange={handleDateChange}
          selectionMode="range"
          readOnlyInput
          optionLabel="NAME"
        />
      </div>
    
    </div>

    {/* Loader mientras carga el iframe */}
    {loadingLooker && <div className="loaderlooker">Cargando tablero...</div>}

    {/* Iframe en su propio renglón */}
    <div style={{ width: '100%' }}>
      <iframe
        src={iframeUrl}
        title="Iframe Example"
        width="1190"  // Asegúrate de que el iframe ocupe el 100% del contenedor
        height="700px"
        style={{ border: "none" }} // Estilo opcional
        onLoad={() => setLoadingLooker(false)} // Cambia el estado de loading cuando se carga
        onError={() => {
          setLoadingLooker(false); // Asegúrate de ocultar el loader si hay un error
          console.error("Error al cargar el iframe");
        }}
      />
    </div>
  </NeoCard>
</NeoTabPanel>
        </NeoTabView>
        </div>
      
      </NeoCard>
     
    </>
  );
}
